/*
 * Copyright 2024 NXP
 */

import banner from "@/public/images/landing_bg_with_circle.png";
import COLORS from "@/src/lib/colors";

const styles = {
  bannerContainer: {
    minHeight: "500px",
    backgroundImage: { xs: "initial", lg: `url("${banner.src}")` },
    backgroundColor: "#353636", // banner background color
    backgroundSize: { xs: "cover", xl: "initial" },
    backgroundPosition: "80% 47%",
    backgroundRepeat: "no-repeat",
  },
  bannerTextContainer: {
    color: "white",
    paddingY: "3rem",
  },
  bannerTitle: { xs: "center", md: "left" } as const,
  denseNavbar: {
    justifyContent: "end",
    backgroundColor: "#ffa700",
    boxShadow: "none",
    color: "white",
  },
  denseNavbarList: { display: "flex" },
  denseNavbarMenu: { mt: "45px" },
  navBar: { backgroundColor: "white" },
  navIcons: { display: { md: "flex", xs: "none" }, paddingY: 0 },
  hamburgerMenuContainer: { display: { xs: "flex", md: "none" } },
  hamburgerMenu: {
    display: { xs: "block", md: "none" },
  },
  hamburgerMenuImage: { marginRight: "10px" },
  alertIcon: { color: COLORS.orange[500] },
  alert: { color: "white", backgroundColor: "rgba(255,255,255,0.15)" },
};

export default styles;
