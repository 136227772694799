/*
 * Copyright 2024 NXP
 */

import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";
import { purple } from "@mui/material/colors";
import COLORS from "@/src/lib/colors";

const WelcomeButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  justifyContent: "flex-start",
  textTransform: "none",
  borderColor: "white",
  border: "2px solid",
  borderRadius: 4,

  "&:hover": {
    border: "2px solid",
    borderImage: `linear-gradient(to right, ${COLORS.orange[500]}, ${COLORS.green}, ${COLORS.blue[400]}) 14% stretch;`,
    "-webkit-mask":
      "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
  },
}));

export default WelcomeButton;
