/*
 * Copyright 2023-2024 NXP
 */

"use client";

import React from "react";
import {
  Alert,
  Box,
  Container,
  CssBaseline,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import Footer from "@/src/components/layout/Footer/Footer";
import InfoTabs from "@/src/components/Views/Dashboard/EmptyDashboard/InfoTabs";
import WelcomeNavBar from "@/src/components/Views/Welcome/WelcomeNavBar";
import styles from "./styles";
import BannerLinks from "@/src/components/Views/Welcome/BannerLinks";
import { HubLinksQuery } from "@/web-builder/api";
import { useTranslations } from "next-intl";
import HelpIcon from "@mui/icons-material/Help";
import COLORS from "@/src/lib/colors";

interface WelcomeCockpitProps {
  staticData: HubLinksQuery;
}

export default function WelcomeCockpit({ staticData }: WelcomeCockpitProps) {
  const t = useTranslations("WelcomeCockpit");

  return (
    <Stack minHeight={"100vh"}>
      <WelcomeNavBar />
      <Stack flex={1}>
        <Box flex={1}>
          <Box sx={styles.bannerContainer}>
            <Container sx={styles.bannerTextContainer}>
              <Stack width={{ xs: "100%", lg: "70%" }} gap={3}>
                <Typography variant={"h3"} textAlign={styles.bannerTitle}>
                  {t("builder")}
                </Typography>
                <Typography>{t("description")}</Typography>
                <BannerLinks
                  hasAch={staticData.appcodehub}
                  hasEbh={staticData.ebhub}
                />
                <Alert
                  icon={<HelpIcon fontSize="small" sx={styles.alertIcon} />}
                  sx={styles.alert}
                >
                  {t("recommendation")}
                </Alert>
              </Stack>
            </Container>
          </Box>
          <InfoTabs white />
        </Box>
        <Container>
          <Footer />
        </Container>
      </Stack>
    </Stack>
  );
}
